body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 16px;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #b7b7b7;
  border-radius: 16px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #8c8c8c;
}

*::-webkit-scrollbar-corner {
  background: transparent;
}


@font-face {
  font-family: Bogle-Black;
  src: url('../assets/fonts/Bogle/Bogle-Black.woff2');
}

@font-face {
  font-family: Bogle-Bold;
  src: url('../assets/fonts/Bogle/Bogle-Bold.woff2');
}

@font-face {
  font-family: Bogle-Medium;
  src: url('../assets/fonts/Bogle/Bogle-Medium.woff2');
}

@font-face {
  font-family: Bogle;
  src: url('../assets/fonts/Bogle/Bogle-Regular.woff2');
}

@import './video.scss';
@import './loading.scss';
@import './transitional-video.scss';
@import './spinner.scss';